import React from 'react';
import './App.css';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import marked from 'marked';

class App extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      isScreenshotOpen: false,
      mailchimpEmail: ""
    }
  }

  render(){
    if ( window.location.pathname === '/' ) {
      return (
        <div className="App">
          <div className="heading">
            <img className="logo" alt="Harkkamatsi.fi" src="logo.png"></img>
            <div className="subheading">Palvelu julkaistaan pian.</div>
          </div>
          <div className="screenshot-wrapper">
            <img onClick={() => {
              this.setState({ isScreenshotOpen: true })
            }} className="screenshot" alt="Harkkamatsi.fi" src="screenshot-tn.png"></img>
            { this.state.isScreenshotOpen &&
              <Lightbox
                  mainSrc={"screenshot.png"}
                  onCloseRequest={() => this.setState({ isScreenshotOpen: false })}
                /> 
            }
          </div>
  
          <div className="mailchimp-wrapper">
            <div id="mc_embed_signup">
              <form action="https://harkkamatsi.us10.list-manage.com/subscribe/post?u=048e43c2cd54f15a92c076ff4&amp;id=e10ebfabfe" 
                method="post" 
                id="mc-embedded-subscribe-form" 
                name="mc-embedded-subscribe-form" 
                className="validateName" 
                target="_blank" 
                noValidate
              >
                <div id="mc_embed_signup_scroll">
                  <label htmlFor="mce-EMAIL">Haluan tiedon lanseerauksesta sähköpostiini! </label>
                  <input type="email" 
                    value={this.state.mailchimpEmail} 
                    name="EMAIL" 
                    className="email" 
                    id="mce-EMAIL" 
                    placeholder="Sähköpostiosoite"
                    onChange={e => {
                      this.setState({mailchimpEmail: e.target.value})
                    }} 
                    required 
                  />
                  <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true">
                    <input type="text" name="b_048e43c2cd54f15a92c076ff4_e10ebfabfe" tabIndex="-1" defaultValue="" />
                  </div>
                  <div className="clear">
                    <input type="submit" value="Tilaa" name="subscribe" id="mc-embedded-subscribe" className="button" />
                  </div>
                </div>
              </form>
            </div>
          </div>
  
          <div className="countdown-timer-wrapper">
            <div className="powr-countdown-timer" id="cb0a5d5d_1592462552"></div>
          </div>
  
          <div className="privacy-wrapper">
            <a href="privacy">
              Tietosuojaseloste
            </a>
          </div>
  
        </div>
      );
    } else if ( window.location.pathname === '/privacy' ) {
      const __html = marked(`
### 1. Rekisterinpitäjä 

Harkkamatsi Oy  
Posti- ja käyntiosoite: 
Heikkiläntie 6 B 30, 00210 Helsinki  
Rekisteri on Harkkamatsi Oy:n käyttäjätietojen tallentamiseen tarkoitettu rekisteri.  
Yhteystiedot rekisteriä koskevissa asioissa  
Harkkamatsi Oy  
Heikkiläntie 6 B 30, 00210 Helsinki, puh 040 578 8059  

### 2. Rekisteröidyt 
Harkkamatsi Oy:tä käyttävät henkilöt ja yhteisöt

### 3. Henkilötietojen käyttötarkoitus 

Henkilötietoja käsitellään rekisteröidyn jäsenyyssuhteen perusteella. 

Henkilötietoja käsitellään vain ennalta määriteltyihin tarkoituksiin, jotka ovat seuraavat: 
- Käyttäjien yksilöinti Harkkamatsi Oy:n tuottamissa sähköisissä palveluissa 
- Merkkipäivien huomiointi 
- Analysointi ja tilastointi 
- Asiakaspalvelun ja toiminnan toteuttaminen ja kehittäminen 
- Markkinoinnin kohdennus 

### 4. Rekisteriin tallennettavat tiedot             

Jäsenrekisteri sisältää sekä henkilöiden tietoja että yritysten kontaktien henkilötietoja. 

#### Yritystiedot
- Yrityksen perustiedot  
- Y-tunnus 
- Toimialatiedot 
- Yrityksen perustamispäivämäärä 
- Yrityksen yhteystiedot 
- Käynti-, postitus- ja laskutusosoite 
- Puhelinnumero 
- Sähköposti 
- Sosiaalisen media verkko-osoitteet
- Asiointikieli 
- Jäsenyrityksen kontaktit, seniorijäsenet ja opiskelijajäsenet 

#### Henkilötiedot
- Nimi 
- Sukupuoli 
- Palvelukieli 
- Syntymäaika 
- Kontaktien yhteystiedot 
- Osoitetiedot 
- Puhelinnumero 
- Sähköposti 

#### Jäsenyyttä koskevat tiedot
- Jäsensuhdetta koskevat tiedot 
- Liittymistiedot 
- Tilaustiedot 
- Asiakaspalautteet ja yhteydenotot 

### 5. Rekisteröidyn oikeudet 

Sinulla on seuraavat oikeudet, joiden käyttämistä koskevat pyynnöt tulee tehdä osoitteeseen: asiakaspalvelu@harkkamatsi.fi. 

#### Tarkastusoikeus 
Sinulla on oikeus tarkistaa sinusta tallentamamme henkilötiedot. Mikäli havaitset tiedoissasi virheellisyyksiä tai puutteita, voit pyytää meitä korjaamaan tai täydentämään tiedot oikeiksi. 

#### Vastustamisoikeus 
Sinulla on oikeus koska tahansa vastustaa henkilötietojesi käsittelyä, mikäli koet, että olemme käsitelleet henkilötietojasi lainvastaisesti tai että meillä ei ole oikeutta käsitellä joitain henkilötietojasi. 

#### Poisto-oikeus 
Mikäli koet, että joidenkin sinua koskevien tietojen käsittely ei ole tarpeen tehtäviemme kannalta, sinulla on oikeus pyytää meitä poistamaan kyseiset tiedot. Käsittelemme pyyntösi, jonka jälkeen joko poistamme tietosi tai ilmoitamme sinulle perustellun syyn, miksi tietoja ei voida poistaa. Mikäli olet eri mieltä ratkaisustamme, sinulla on oikeus tehdä valitus tietosuojavaltuutetulle (ohjeet valituksen tekemiseen). Sinulla on myös oikeus vaatia, että rajoitamme kiistanalaisten tietojen käsittelyä siksi aikaa, kunnes asia saadaan ratkaistua. 

#### Valitusoikeus 
Sinulla on oikeus tehdä valitus tietosuojavaltuutetulle, jos koet, että rikomme henkilötietojasi käsitellessämme voimassa olevaa tietosuojalainsäädäntöä (ohjeet valituksen tekemiseen). 

### 6. Säännönmukaiset tietolähteet 

Rekisteröitynyttä koskevat tiedot saadaan säännönmukaisesti: 
- Rekisteröityneeltä itseltään suoraan järjestelmään tai sähköpostitse, puhelimitse, lomakkeella, mobiilisovelluksen kautta tai muulla vastaavalla tavalla 

### 7. Säännönmukaiset tietojen luovutukset 

Emme myy tai vuokraa jäsenrekisterin tietoja kolmansille osapuolille. Luovutamme tietoja kolmansille osapuolille vain seuraavissa tapauksissa: 
- Voimme luovuttaa käyttäjän tietoja kolmansille osapuolille, mikäli käyttäjä on antanut siihen suostumuksensa. 
- Voimme luovuttaa tietoja yhteistyökumppaneille, jotta he osaavat tarjota jäsenyyteen kuuluvia etuja jäsenyrityksille. 
- Voimme luovuttaa tietoja tilastollista, tieteellistä tai historiallista tutkimusta varten edellyttäen, että tiedot on muutettu sellaiseen muotoon, että tiedon kohde ei enää ole niistä tunnistettavissa. 
- Mikäli fuusioimme tai muutoin järjestelemme toimintaamme, käyttäjän henkilötietoja voidaan luovuttaa uuden organisaation sisällä. 

### 8. Käsittelyn kesto 

Henkilötietoja käsitellään niin kauan kuin jäsenyys on voimassa.  

### 9. Henkilötietojen käsittelijät 

Jäsenrekisterin tietoja käsittelevät Harkkamatsi Oy:n palkatut työntekijät omien jäsenlistojensa osalta. 

Voimme myös ulkoistaa henkilötietojen käsittelyn osittain kolmannelle osapuolelle, jolloin takaamme sopimusjärjestelyin, että henkilötietoja käsitellään voimassa olevan tietosuojalainsäädännön mukaisesti ja muutoin asianmukaisesti. 

### 10. Tietojen siirto EU:n ulkopuolelle 

Henkilötietoja ei siirretä EU:n tai Euroopan talousalueen ulkopuolelle. 

Jos jonkun palvelukumppaniemme palvelimet sijaitsevat EU/ETA-alueen ulkopuolelle Yhdysvaltoissa tiedot varmuuskopioidaan, varmistamme, että he ovat liittyneet EU:n ja Yhdysvaltain väliseen ns. Privacy Shield -ohjelmaan (https://www.privacyshield.gov/list), jonka tarkoituksena on varmistaa eurooppalaisten tietojen tietoturvallinen käsittely Yhdysvalloissa. 

### 11. Evästeet ja selailun seuranta 

Voimme kerätä käyttäjän päätelaitetta koskevia tietoja evästeiden ("cookies") ja muiden vastaavien tekniikoiden, kuten selaimen paikallisen tietovaraston, avulla. Eväste on pieni tekstitiedosto, jonka selain tallentaa käyttäjän päätelaitteelle. Evästeet sisältävät usein nimettömän, yksilöllisen tunnisteen, jonka avulla voimme tunnistaa ja laskea sivustollamme vierailevat selaimet. 

Kolmannet osapuolet voivat asettaa evästeitä käyttäjän päätelaitteelle käyttäjän vieraillessa palveluissamme tilastoidakseen eri sivustojen kävijämääriä. Kolmansilla osapuolilla tarkoitetaan Suomen Yrittäjien ulkopuolisia tahoja, kuten mittaus- ja seurantapalveluiden tarjoajia. 

Palvelumme voivat myös sisältää linkkejä muihin sivustoihin ja yhteisöpalveluihin, mutta emme vastaa näiden ulkopuolisten sivustojen yksityisyydensuojakäytännöistä tai sisällöistä. Suosittelemme tutustumaan näiden palveluiden yksityisyydensuojaa koskeviin ehtoihin. 

### 12. Mobiilisovellusten yksityisyydensuojaehdot 

Mahdollisiin Mobiilisovelluksiimme, jotka on saatettu käyttäjän saataville esimerkiksi Applen App Storen, Googlen Playn tai Microsoft Storen kautta, sovelletaan tämän tietosuojaselosteen lisäksi myös kyseisen palveluntarjoajan ehtoja. Lisäksi käyttäjän tulee hyväksyä sovelluksen omat käyttöehdot. 

### 13. Automaattinen päätöksenteko ja profilointi 

Emme käytä tietoja automaattiseen päätöksentekoon tai profilointiin. 

### 14. Rekisterin suojauksen periaatteet 

Henkilötietojesi turvallinen käsittely on meille tärkeää. Käytämme seuraavia suojatoimia tietojesi turvallisuuden varmistamiseksi. 
- Järjestelmään sisäänpääsy edellyttää käyttäjätunnuksen ja salasanan syöttämistä. Järjestelmä on myös suojattu palomuurein ja muiden teknisten keinojen avulla. 
- Järjestelmään tallennettuihin rekisterin sisältämiin tietoihin pääsevät ja niitä ovat oikeutettuja käyttämään vain tietyt, ennalta määritellyt rekisterinpitäjän työntekijät. 
- Rekisterin käyttö on suojattu käyttäjäkohtaisin tunnuksin, salasanoin sekä käyttöoikeuksin. 
- Rekisteri sijaitsee tietokoneessa, joka on sijoitettu konesaliin palvelimelle, jonne pääsy asiattomilta on estetty. 
- Rekisterin sisältämät tiedot sijaitsevat lukituissa ja vartioiduissa tiloissa. 
- Rekisteristä otetaan säännöllisesti varmuuskopiot.`)
    return <div className="Privacy" dangerouslySetInnerHTML={{__html}} />
    }
  }
}

export default App;
